<template>
  <div class="error-msg">
    <div class="container mt-5 pt-5">
      <p class="text-center pt-5">
        <span class="material-icons-outlined display-3 text-main">
          error_outline
        </span>
      </p>
      <h3 class="text-center">目前店家忙碌中，已關閉線上{{brand_type === 2 ? '下單' : '點餐'}}服務。</h3>
      <p class="text-center py-3 text-muted">
        感謝您的來訪，<br />
        但目前線上{{brand_type === 2 ? '下單' : '點餐'}}服務關閉中，您可先去店家官網、粉絲團、Line官方帳號逛逛。
      </p>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
import storage from "@u/storage";
storage.config = { type: "session" };
export default {
  name: "Closed",
  components: {},
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  emits: ["onLoading"],
  setup(props, { emit }) {
    emit("onLoading", false);
    const brand = storage.getItem('brand');
    const brand_type = ref(brand.brand_type)
    return {
      // data
      brand_type
    };
  },
};
</script>
